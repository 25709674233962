import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout.jsx";
import SEO from "../components/seo";

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
        query MyJmpQuery {
            file(relativePath: {eq: "NYC_Parks_Diversity.pdf"}) {
                publicURL
                name
            }
        }
    `);
    
  const url = typeof window !== 'undefined' ? window.location.href : '';
  if (url.includes('NYC_Parks_Diversity')) {
    return <head>
        <meta http-equiv="refresh" content={"0; URL=" + data.file.publicURL} />
        <title>Job Market Paper</title>
    </head>
  }
  else {
    return (
    <Layout>
      <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Share+Tech+Mono&family=Roboto&family=Roboto+Condensed&family=Source+Sans+Pro&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Oswald&display=swap"
          rel="stylesheet"
        ></link>
        <script src="https://use.fontawesome.com/7129732b1e.js"></script>
      <SEO title="404: Not found" />
      <h2> No such page exists, yet.<br/><br/>You can find my Job Market Paper here: <a href="https://tabbiasov.me/jmp">tabbiasov.me/jmp</a></h2>
    </Layout>
    )
    };
  }
  

export default NotFoundPage;
